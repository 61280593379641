import React from 'react';
import { Select } from 'antd';

const SearchInput = Loader.loadBusinessComponent('SearchInput')
const Option = Select.Option;
export default ({ searchData, onSearch, deviceGroup ,typeChange,groupChange,DeviceType}) => {
  return (
    <div class='resource-management-container clearfix'>
      <div className='fl'>
        <div className='label'>
          场所下设备列表：
        </div>
        <Select placeholder="全部类型" value={searchData.deviceTypes&&searchData.deviceTypes.join(',')} style={{ width: 120 ,marginRight:8}} onChange={typeChange}>
          {DeviceType.map(item => (
            <Option title={item.label} key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
        <Select placeholder="全部分组" value={searchData.lygroupId} style={{ width: 120 ,marginRight:8}} onChange={groupChange}>
          {deviceGroup.map(item => (
            <Option title={item.label} key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>

      </div>
      <div className='fr'>
        <SearchInput 
          value={searchData.keywords}
          onChange = {
            value => {
              onSearch({
                keywords:value,
              })
            }
          }
          style={{width:200}}
          placeholder='请输入名称、SN、CID查询'
        />
      </div>
    </div>
  );
};
